<template>
    <div class="card">
        <Accordion>
            <AccordionTab :header="$t('Impact')">
                <Button icon="pi pi-plus" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" v-tooltip.bottom="$t('New')" @click="New('impact')" v-if="insert"/>
                <DataTable :value="products.impact" :paginator="false" stripedRows class="p-datatable-customers p-datatable-sm"
                    :rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters"
                    scrollable scrollHeight="200px" editMode="row"  v-model:editingRows="editingRows1" 
                    @row-edit-init="Init1" @row-edit-save="Save1" @row-edit-cancel="Cancel1">
                    <Column field="id" :header="$t('Score')" headerStyle="width: 10%">
						<template #body="{data}">
							{{ data.id }}
						</template>
						<template #editor="{ data }">
                            <InputText v-model="data.id" type="number" style="width: 80%"/>
						</template>
					</Column>
                    <Column field="en" :header="$t('English')" headerStyle="width: 30%">
						<template #body="{data}">
                            {{data.en}}
						</template>
						<template #editor="{ data }">
                            <InputText id="name" v-model="data.en" :placeholder="$t('English')" style="width: 90%"/>
						</template>
					</Column>
                    <Column field="name" :header="$t('Spanish')" headerStyle="width: 30%">
						<template #body="{data}">
                            {{data.es}}
						</template>
						<template #editor="{ data }">
                            <InputText id="name" v-model="data.es" :placeholder="$t('Spanish')" style="width: 90%"/>
						</template>
					</Column>
                    <Column field="activo" :header="$t('Status')" headerStyle="width: 15%">
						<template #body="{data}">
                            <span class="p-tag p-tag-info"  v-if="data.activo">{{$t('Asset')}}</span>
                            <span class="p-tag p-tag-info"  v-else>{{$t('Inactive')}}</span>
						</template>
						<template #editor="{ data }">
                            <InputSwitch v-model="data.activo" />
						</template>
					</Column>
                    <Column headerStyle="width: 10%" :rowEditor="true"/>
                    <Column headerStyle="width: 5%">
                        <template #editor="{data}">
                            <Button icon="pi pi-trash" class="p-button-text p-button-secondary" @click="Delete('impact',data)" v-if="del"  v-tooltip.bottom="$t('Delete')"/>
						</template>
                    </Column>
                </DataTable>
            </AccordionTab>
            <AccordionTab :header="$t('Likelihood')">
                <Button icon="pi pi-plus" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" v-tooltip.bottom="$t('New')" @click="New('likelihood')" v-if="insert"/>
                <DataTable :value="products.likelihood" :paginator="false" stripedRows class="p-datatable-customers p-datatable-sm"
                    :rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters"
                    scrollable scrollHeight="200px" editMode="row"  v-model:editingRows="editingRows2" 
                    @row-edit-init="Init2" @row-edit-save="Save2" @row-edit-cancel="Cancel2">
                    <Column field="id" :header="$t('Score')" headerStyle="width: 10%">
						<template #body="{data}">
							{{ data.id }}
						</template>
						<template #editor="{ data }">
                            <InputText v-model="data.id" type="number" style="width: 80%"/>
						</template>
					</Column>
                    <Column field="en" :header="$t('English')" headerStyle="width: 30%">
						<template #body="{data}">
                            {{data.en}}
						</template>
						<template #editor="{ data }">
                            <InputText id="name" v-model="data.en" :placeholder="$t('English')" style="width: 90%"/>
						</template>
					</Column>
                    <Column field="name" :header="$t('Spanish')" headerStyle="width: 30%">
						<template #body="{data}">
                            {{data.es}}
						</template>
						<template #editor="{ data }">
                            <InputText id="name" v-model="data.es" :placeholder="$t('Spanish')" style="width: 90%"/>
						</template>
					</Column>
                    <Column field="activo" :header="$t('Status')" headerStyle="width: 15%">
						<template #body="{data}">
                            <span class="p-tag p-tag-info"  v-if="data.activo">{{$t('Asset')}}</span>
                            <span class="p-tag p-tag-info"  v-else>{{$t('Inactive')}}</span>
						</template>
						<template #editor="{ data }">
                            <InputSwitch v-model="data.activo" />
						</template>
					</Column>
                    <Column headerStyle="width: 10%" :rowEditor="true"/>
                    <Column headerStyle="width: 5%">
                        <template #editor="{data}">
                            <Button icon="pi pi-trash" class="p-button-text p-button-secondary" @click="Delete('likelihood',data)" v-if="del"  v-tooltip.bottom="$t('Delete')"/>
						</template>
                    </Column>
                </DataTable>
            </AccordionTab>
            <AccordionTab :header="$t('Score')">
                <Button icon="pi pi-plus" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" v-tooltip.bottom="$t('New')" @click="New('score')" v-if="insert"/>
                <DataTable :value="products.score" :paginator="false" stripedRows class="p-datatable-customers p-datatable-sm"
                    :rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters"
                    scrollable scrollHeight="200px" editMode="row"  v-model:editingRows="editingRows3" 
                    @row-edit-init="Init3" @row-edit-save="Save3" @row-edit-cancel="Cancel3">
                    <Column field="min" :header="$t('Minimal')" headerStyle="width: 10%">
						<template #body="{data}">
							{{ data.min }}
						</template>
						<template #editor="{ data }">
                            <InputText v-model="data.min" type="number" style="width: 80%"/>
						</template>
					</Column>
                    <Column field="max" :header="$t('Maximum')" headerStyle="width: 10%">
						<template #body="{data}">
							{{ data.max }}
						</template>
						<template #editor="{ data }">
                            <InputText v-model="data.max" type="number" style="width: 80%"/>
						</template>
					</Column>
                    <Column field="en" :header="$t('English')" headerStyle="width: 25%">
						<template #body="{data}">
                            {{data.en}}
						</template>
						<template #editor="{ data }">
                            <InputText id="name" v-model="data.en" :placeholder="$t('English')" style="width: 90%"/>
						</template>
					</Column>
                    <Column field="name" :header="$t('Spanish')" headerStyle="width: 25%">
						<template #body="{data}">
                            {{data.es}}
						</template>
						<template #editor="{ data }">
                            <InputText id="name" v-model="data.es" :placeholder="$t('Spanish')" style="width: 90%"/>
						</template>
					</Column>
                    <Column field="color" :header="$t('Color')" headerStyle="width: 25%">
						<template #body="{data}">
                            <Tag :style="'width: 100%; height: 30px;background-color: #'+data.color">
                                {{data.color}}
                            </Tag>
						</template>
						<template #editor="{data}">
                            <ColorPicker  v-model="data.color" style="width: 40%"/>
                            <InputText id="name" v-model="data.color" style="width: 50%"/>
						</template>
					</Column>
                    <Column headerStyle="width: 10%" :rowEditor="true"/>
                    <Column headerStyle="width: 5%">
                        <template #editor="{data}">
                            <Button icon="pi pi-trash" class="p-button-text p-button-secondary" @click="Delete('score',data)" v-if="del"  v-tooltip.bottom="$t('Delete')"/>
						</template>
                    </Column>
                </DataTable>
            </AccordionTab>
            <AccordionTab :header="$t('Impact')+' x '+$t('Likelihood')">
				<DataTable :value="products.cruze" :paginator="false" stripedRows class="p-datatable-customers p-datatable-sm"
                    :rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters"
                    editMode="row"  v-model:editingRows="editingRows4" 
                    @row-edit-init="Init4" @row-edit-save="Save4" @row-edit-cancel="Cancel4">
                    <Column field="name" header="*">
						<template #body="{data}">
                            <strong>{{data.impact}} {{data[i18n.locale()]}}</strong>
						</template>
						<template #editor="{ data }">
                            <strong>{{data.impact}} {{data[i18n.locale()]}}</strong>
						</template>
					</Column>
                    <Column v-for="col of products.likelihood" :key="col.id" :field="col.en" :header="col.id+' '+col[i18n.locale()]" bodyStyle="text-align: center;" headerStyle="text-align: center;">
                        <template #body="{data}">
							<Tag :style="'height: 40px; background-color:#'+Color(data[col.en])">
                                {{data[col.en]}}
							</Tag>
						</template>
						<template #editor="{ data }">
							<InputText v-model="data[col.en]" type="number" style="width: 50%; text-align: center;" :min="products.min" :max="products.max"/>
						</template>
                    </Column>
					<Column headerStyle="width: 10%" :rowEditor="true"/>
                </DataTable>
            </AccordionTab>
        </Accordion>
	</div>
</template>

<script>
	import API from "../service/API";
	var Consult = new API('Parameters','ScoringRisk');
	import { useI18nPlugin } from '@unify/vuex-i18n';

	export default {
		props: {
			c_insert: {
				type: Boolean,
				default: false,
			},
			c_edit: {
				type: Boolean,
				default: false,
			},
			c_del: {
				type: Boolean,
				default: false,
			},
			dats: {
				type: Object,
				default: null,
			},
		},
		data() {
			return {
				insert: this.c_insert,
				edit: this.c_edit,
				del: this.c_del,
				i18n: null,
                editingRows: [],
                editingRows1: [],
                editingRows2: [],
                editingRows3: [],
                editingRows4: [],
				products: this.dats,
				product: {},
				newid: false,
				search: null,
				options: null,
				option: null,
				productDialog: false,
				selectedCustomers: null,
				filters: {},
				size: '60vw',
                dat_ini: null,
				editing: false,
			}
		},
		productService: null,
		created() {
			this.i18n = useI18nPlugin();
			this.$store.commit('Validate',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
		},
		mounted() {
			
		},
		methods: {
			Init1(event) {
				let { data, index } = event;
				this.products.impact[index].value = {...data};
			},
			Init2(event) {
				let { data, index } = event;
				this.products.likelihood[index].value = {...data};
			},
			Init3(event) {
				let { data, index } = event;
				this.products.score[index].value = {...data};
			},
			Init4(event) {
				let { data, index } = event;
				this.products.cruze[index].value = {...data};
			},
			Cancel1(event) {
				let { index } = event;
				this.products.impact[index] = this.products.impact[index].value;
			},
			Cancel2(event) {
				let { index } = event;
				this.products.likelihood[index] =  this.products.likelihood[index].value;
			},
			Cancel3(event) {
				let { index } = event;
				this.products.score[index] =  this.products.score[index].value;
			},
			Cancel4(event) {
				let { index } = event;
				this.products.cruze[index] =  this.products.cruze[index].value;
			},
			Save1(event) {
				let { data } = event;
				data.cass = 2;
				this.Save('impact',data);
			},
			Save2(event) {
				let { data } = event;
				data.cass = 3;
				this.Save('likelihood',data);
			},
			Save3(event) {
				let { data } = event;
				data.cass = 4;
				this.Save('score',data);
			},
			Save4(event) {
				let { data } = event;
				data.cass = 5;
				this.Save('cruze',data);
			},
            Color(value){
                let dat = this.products.score.filter(val => parseFloat(val.min)<=parseFloat(value) && parseFloat(value)<=parseFloat(val.max));
                if(dat.length>0){
                    dat = dat[0].color;
                } else {
                    dat = 'white';
                }
                return dat;
            },
			New(cass) {
				var product = {};
				switch(cass) {
					case "impact":
						product.cass = 2;
						product.es = '-';
						product.en = '-';
						product.activo = 0;
						break;
					case "likelihood":
						product.cass = 3;
						product.es = '-';
						product.en = '-';
						product.activo = 0;
						break;
					case "score":
						product.cass = 4;
						product.es = '-';
						product.en = '-';
						product.min = 0;
						product.max = 0;
						product.color = 'ffffff';
						break;
				}
				Consult.Process('INS',{
					inf: product,
				}).then(response => {
					//this.$store.state.error = response;
					if (response.result) {
						switch(cass) {
							case "impact":
								this.products.impact = response.result;
								this.Cruze();
								break;
							case "likelihood":
								this.products.likelihood = response.result;
								this.Cruze();
								break;
							case "score":
								this.products.score = response.result;
								break;
						}
					} 
				});
			},
			Save(cass,product) {
				Consult.Process('UPD',{
					inf: product,
				}).then(response => {
					//this.$store.state.error = response;
					this.$toast.add(Consult.Mens(response.result,this.i18n.locale()));
					if (response.result) {
						switch(cass) {
							case "impact":
								this.products.impact = response.result;
								this.Cruze();
								break;
							case "likelihood":
								this.products.likelihood = response.result;
								this.Cruze();
								break;
							case "score":
								this.products.score = response.result;
								break;
							case "cruze":
								this.products.cruze = response.result;
								break;
						}
					} 
				});
			},
			Delete(cass,product) {
				switch(cass) {
					case "impact":
						product.cass = 2;
						break;
					case "likelihood":
						product.cass = 3;
						break;
					case "score":
						product.cass = 4;
						break;
				}
				Consult.Process('DEL',{
					inf: product
				}).then(response => {
					//this.$store.state.error = response;
					this.$toast.add(Consult.Mens(response.result,this.i18n.locale()));
					if (response.result) {
						switch(cass) {
							case "impact":
								this.products.impact = response.result;
								this.Cruze();
								break;
							case "likelihood":
								this.products.likelihood = response.result;
								this.Cruze();
								break;
							case "score":
								this.products.score = response.result;
								break;
						}


					} 			
				});
			},
			Cruze() {
				Consult.Process('Cruze',{
					inf: null,
				}).then(response => {
					//this.$store.state.error = response;
					if (response.result) {
						this.products.cruze = response.result;
					} 
				});
			},

		}
	}
</script>

<style scoped lang="scss">
.ttitle{
    font-family: 'Rajdhani', sans-serif;
    font-size: calc(0.7rem + 1vw);
    font-weight: 600;
    color: #2b3b80;
    padding: 1em 0em 1em 0.5em;
}
.text-title{
        font-family: 'Rajdhani', sans-serif;
        font-size: calc(0.7rem + 1vw);
        font-weight: 600;
        color: #2b3b80;
    }

	::v-deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}
}

.p-datatable .p-column-filter {
	display: none;
}

.table-header {
	display: flex;
	justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
	.p-datatable-header {
		padding: 1rem;
		text-align: left;
		font-size: 1.5rem;
	}

	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead > tr > th {
		text-align: left;
	}

	.p-datatable-tbody > tr > td {
		cursor: auto;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

.customer-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-qualified {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-unqualified {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-negotiation {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.status-new {
		background: #B3E5FC;
		color: #23547B;
	}

	&.status-renewal {
		background: #ECCFFF;
		color: #694382;
	}

	&.status-proposal {
		background: #FFD8B2;
		color: #805B36;
	}
}

.p-progressbar-value.ui-widget-header {
	background: #607d8b;
}

@media (max-width: 640px) {
	.p-progressbar {
		margin-top: .5rem;
	}
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
	padding: 1rem;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.order-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.order-delivered {
		background: #C8E6C9;
		color: #256029;
	}

	&.order-cancelled {
		background: #FFCDD2;
		color: #C63737;
	}

	&.order-pending {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.order-returned {
		background: #ECCFFF;
		color: #694382;
	}
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}
}
</style>